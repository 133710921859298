import Vue from 'vue';
import App from './App.vue';
import './js/registerServiceWorker';
import router from './js/routes';

Vue.config.productionTip = false;
import './css/bootstrap/bootstrap.scss';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import './css/app.scss';
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
