<template>
  <div>

    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">

          <h1>
            <i class="bi bi-patch-question"></i>
            Pacjent
          </h1>
        </div>
        <div class="col">
          <ul class="steps">
            <li></li>
            <li class="active"></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>

    <template v-if="!loading">
      <form @submit.prevent="onSubmit">
        <div class="row align-items-center">
          <div class="col d-none d-sm-none d-md-block">
            <img src="../assets/question.avif" class="img-fluid">
          </div>
          <div class="col">

            <template v-if="questions.length">
              <div class="alert alert-info  d-flex align-items-center" role="alert">
                <i class="bi bi-info-square me-3" style="font-size: 2rem;"></i>
                Udziel odpowiedzi na poniższe pytania.
              </div>
              <div class="alert alert-danger" v-if="error" role="alert">
                Wystąpił błąd.
              </div>
              <template v-for="question in questions">
                <div class="mb-3" :key="question.id">
                  <label class="form-label">{{ question.question }}</label>
                  <textarea class="form-control" rows="1" :disabled="loading" v-model="question.answer" required style="resize: none;"></textarea>
                </div>
              </template>
              <div class="button-actions text-end mt-5">
                <button class="btn btn-lg btn-outline-primary" :disabled="loading" type="submit">
                  Przejdź dalej
                  <div class="spinner-border text-primary" v-if="loading" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </div>
            </template>
            <div v-else class="alert alert-danger">
              Brak konfiguracji wywiadu.
            </div>
          </div>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src


import Config from "@/js/config";


export default {
  name: 'VerifyPage',
  data() {
    return {
      uuid: localStorage.getItem('uuid'),
      loading: true,
      error: false,
      questions: [],
    };
  },

  methods: {
    async onSubmit() {
      this.loading = true;
      const rawResponse = await fetch(`${Config.apiUrl}/patients/base-question/${this.uuid}.json`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.questions)
      });
      const content = await rawResponse.json();
      if (content.success) {
        localStorage.setItem('step', 'symptoms');
        return this.$router.push({name: 'symptoms'});
      }
    },
    async loadQuestions() {
      this.loading = true;
      let uuid = localStorage.getItem('uuid');
      const rawResponse = await fetch(`${Config.apiUrl}/patients/base-question/${uuid}.json`);
      const content = await rawResponse.json();

      if (content.success) {
        this.questions = content.payload.questions;
      } else {
        this.error = true;
      }
      this.loading = false;
    }
  },
  created() {
    if (!this.uuid) {
      return this.$router.push({name: 'verify'});
    }


    this.loadQuestions();
  }
}
</script>
