<template>
  <div>

    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h1>
            <i class="bi bi-card-checklist"></i>
            Identyfikacja
          </h1>
        </div>
        <div class="col">
          <ul class="steps">
            <li class="active"></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
    <form @submit.prevent="onSubmit" >
      <div class="row align-items-center">
        <div class="col d-none d-sm-none d-md-block">
          <img src="../assets/verify.png" class="img-fluid">
        </div>
        <div class="col">
          <div class="alert alert-info  d-flex align-items-center" role="alert">
            <i class="bi bi-info-square me-3" style="font-size: 2rem;"></i>
            Poniżej wprowadź numer idetyfikacyjny otrzymany od lekarza.
          </div>
          <div class="alert alert-danger" v-if="error" role="alert">
            Wprowadzono błędny numer pacjenta.
          </div>

          <div class="mb-3">
            <input type="text" :disabled="loading" masked="false" v-mask="'###-###-###'" class="form-control form-control-lg" v-model="uuid" required>
          </div>
          <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" value="" id="flexCheck1" required>
            <label class="form-check-label" for="flexCheck1">
              Znam i akceptuję <a href="#">Regulamin serwisu</a>.
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="flexCheck2" value="" required>
            <label class="form-check-label" for="flexCheck2">
              Wyrażam zgodę na wykorzystanie informacji o moim stanie zdrowia na potrzeby wywiadu. Więcej informacji:
              <a href="#">Polityka prywatności</a>
            </label>
          </div>
          <div class="button-actions text-end mt-5">
            <button class="btn btn-lg btn-outline-primary" :disabled="loading" type="submit">
              Przejdź dalej
              <div class="spinner-border text-primary" v-if="loading" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>

          </div>

        </div>

      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src


import Config from "@/js/config";

export default {
  name: 'VerifyPage',
  data() {
    return {
      uuid: '',
      loading: false,
      error: false,
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.error = false;

      this.verify();
    },
    async verify() {
      let uuid = this.uuid.replaceAll("-", "");
      const rawResponse = await fetch(`${Config.apiUrl}/patients/verify/${uuid}.json`);
      const content = await rawResponse.json();

      if (content.success) {
        localStorage.setItem('uuid', content.payload.uuid);
        this.$router.push({name: 'interview'});
      } else {
        this.error = true;
      }
      this.loading = false;
    }
  },
  created() {
    localStorage.removeItem('uuid');
    localStorage.setItem('step', 'verify');
  }
}
</script>
