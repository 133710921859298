<template>
  <div>
    <div class="row center align-items-center">
      <div class="col-sm-6 col-12">
        <img src="../assets/complete.png" class="img-fluid">
      </div>
      <div class="col">
        <h1 class="mb-4">
          Wywiad zakończony
        </h1>
        <h4>Dziękujemy za wypełnienie formularza.</h4>
        <h7 class="">Dane zostały przekazane do lekarza w celu analizy.</h7>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'SymptomsPage',
  data() {
    return {
      uuid: localStorage.getItem('uuid'),
      loading: false,
      error: false,
      tag: '',
      symptoms: [],
    };
  },
  created() {
    localStorage.removeItem('uuid');

  }
}
</script>
