<template>
  <div>
    <Loader :active="loading">
      <h3>Trwa analiza danych</h3>
    </Loader>
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h1>
            <i class="bi bi-heart-pulse"></i>
            Objawy
          </h1>
        </div>
        <div class="col">
          <ul class="steps">
            <li></li>
            <li></li>
            <li class="active"></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="alert alert-info  d-flex align-items-center" role="alert">
      <i class="bi bi-info-square me-3" style="font-size: 2rem;"></i>
      Ponieżej wprowadź conajmniej 3 objawy, którę Ci towarzyszą. Zatwierdz enterem.
    </div>
    <div class="alert alert-danger" v-if="error" role="alert">
      Wprowadź odpowiednią liczbę objawów aby przejść dalej.
    </div>
    <div class="mb-3">
      <vue-tags-input
          v-model="tag"

          :tags="symptoms"
          :max-tags="5"
          placeholder="Dodaj objaw"
          class="form-control form-control-lg"
          @tags-changed="newTags => symptoms = newTags"
      />
    </div>
    <div class="mb-3">
      Wprowadzone objawy: <b class="text-success" :class="{'text-danger': symptoms.length  < 3 }">{{ symptoms.length }}</b>
    </div>
    <div class="button-actions text-end mt-5">
      <button class="btn btn-lg btn-outline-primary" @click="onSubmit" :disabled="loading" type="submit">
        Przejdź dalej
        <div class="spinner-border text-primary" v-if="loading" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>

  </div>
</template>

<script>

import VueTagsInput from '@johmun/vue-tags-input';
import Loader from "@/components/Loader";
import Config from "@/js/config";

export default {
  name: 'SymptomsPage',
  data() {
    return {
      uuid: localStorage.getItem('uuid'),
      loading: false,
      error: false,
      tag: '',
      symptoms: [],
    };
  },
  components: {
    Loader,
    VueTagsInput
  },
  methods: {
    async onSubmit() {
      this.error = false;

      if (this.symptoms.length < 3) {
        this.error = true;
        return;
      }

      this.loading = true;
      const rawResponse = await fetch(`${Config.apiUrl}/patients/symptoms/${this.uuid}.json`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.symptoms)
      });
      const content = await rawResponse.json();
      if (content.success) {
        localStorage.setItem('step', 'questions');
        return this.$router.push({name: 'questions'});
      }
    }
  },
  created() {
    if (!this.uuid) {
      return this.$router.push({name: 'verify'});
    }

  }
}
</script>
